import { getLpDestinations } from '~/helpers/strapi/getLpDestinations'
import { getLpInspirations } from '~/helpers/strapi/getLpInspirations'

export const getLpContentData = async ({ $strapiApi, slug }) => {
  try {
    const allLpContent = await Promise.all([
      getLpDestinations({ $strapiApi, slug }),
      getLpInspirations({ $strapiApi, slug }),
    ])
    const isLpDestination = allLpContent[0] !== null
    const isLpInspiration = allLpContent[1] !== null
    const lpContent = allLpContent?.find(
      (content) => content !== null && content?.published,
    )

    if (lpContent) {
      const defaultLocalizedValue = { en: '', fr: '' }

      return {
        coverLabel: lpContent.cover_label || defaultLocalizedValue,
        coverPhotoAlt: lpContent.cover_photo_alt || defaultLocalizedValue,
        coverPhotoUrl: lpContent.cover_photo_url,
        coverSubtitle: lpContent.cover_subtitle || defaultLocalizedValue,
        coverTitle: lpContent.cover_title || defaultLocalizedValue,
        description: lpContent.description || defaultLocalizedValue,
        destinationId: lpContent.destination_id,
        howToGetThere: lpContent.how_to_get_there || defaultLocalizedValue,
        id: lpContent.id,
        isLpDestination,
        isLpInspiration,
        metaDescription: lpContent.meta_description || defaultLocalizedValue,
        metaTitle: lpContent.meta_title || defaultLocalizedValue,
        published: lpContent.published,
        searchButtonOnDestinationPage:
          lpContent.search_button_on_destination_page || false,
        searchUrl: lpContent.search_url || defaultLocalizedValue,
        seoFeaturedPhotoDescription:
          lpContent.seo_featured_photo_description || defaultLocalizedValue,
        seoPhotoUrl: lpContent.seo_photo_url,
        seoSectionTitle: lpContent.seo_section_title || defaultLocalizedValue,
        slugEn: lpContent.slug_en,
        slugFr: lpContent.slug_fr,
        title: lpContent.title || defaultLocalizedValue,
        whenToLeaveText: lpContent.when_to_leave_text || defaultLocalizedValue,
      }
    }

    return null
  } catch (err) {
    console.error(err)
  }
}
