export const getLpDestinations = ({ slug, $strapiApi }) => {
  return $strapiApi
    .getLpDestinationBySlug(slug)
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}
