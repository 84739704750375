import { validateDestinationSlug } from '~/helpers/validateDestinationSlug.js'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $api, $strapiApi, $i18n } = useNuxtApp()
  const { setRouteParams } = useSetRouteParams()
  const { setCustomPageTheme, setTemporaryTheme } = useHeaderNavigation()

  const { valid, isDesti, isLp, isLpDesti, isLpInspi, isSeoFacet, searchUrl } =
    await validateDestinationSlug({
      $api,
      $strapiApi,
      params: to.params,
      locale: $i18n.locale,
    })

  if (!valid) {
    throw showError({ statusCode: 404 })
  }

  to.meta.destinationPage = {
    isDesti,
    isLp,
    isLpDesti,
    isLpInspi,
    isSeoFacet,
  }

  if (isLpDesti || isDesti) {
    try {
      const destination = await $api.v3.destination.getOneBySlug({
        slug: to.params.name,
        fields: {
          destination: ['isCountry', 'isCluster'],
        },
      })
      const ancestries = await $api.v3.destination.getAncestries({
        slug: to.params.name,
      })
      const pageType = getTrackViewPageType(destination.data)

      to.meta.gtmAdditionalEventData = {
        page_type: pageType,
      }
      to.meta.ancestries = {
        clusterId: ancestries.cluster?.id,
        clusterName: ancestries.cluster?.name,
        countryId: ancestries.country?.id,
        countryName: ancestries.country?.name,
        destinationId: ancestries.desti?.id,
        destinationName: ancestries.desti?.name,
      }
    } catch {
      throw showError({ statusCode: 404 })
    }
  } else if (isSeoFacet) {
    to.meta.gtmAdditionalEventData = {
      page_type: 'search_filtered',
    }
  }

  setTemporaryTheme(isLp ? 'transparent' : 'white')
  setCustomPageTheme(isLp ? 'transparent' : 'white')

  setRouteParams({
    slugEn: searchUrl.en,
    slugFr: searchUrl.fr,
  })
})
