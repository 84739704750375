import { getDestinationSlug } from '~/helpers/getDestinationSlug'
import { getLpContentData } from '~/helpers/search/searchDesti.js'
import { getFacetsFilterWithoutDestination } from '~/helpers/strapi/getFacetsFilter'

export const validateDestinationSlug = async ({
  $api,
  $strapiApi,
  params,
  locale,
}) => {
  const destinationSlugs = {
    fr: ['location-chalets-luxe', 'location-villas-luxe'],
    en: ['luxury-chalets-rentals', 'luxury-villas-rentals'],
  }

  const response = {
    isDesti: false,
    isLp: false,
    isLpDesti: false,
    isLpInspi: false,
    isSeoFacet: false,
    valid: false,
  }

  // If params is a destination slug
  if (destinationSlugs[locale.value].includes(params.destination)) {
    try {
      // If destination exist
      const res = await $api.v3.destination.getOneBySlug({
        slug: params.name,
        fields: {
          destination: ['searchUrl'],
        },
      })

      if (res?.data) {
        const { searchUrl } = res.data
        const slug = getDestinationSlug(searchUrl)[locale.value]

        const valid =
          `${params.destination}-${params.name}` ===
          `${slug.destination}-${slug.name}`

        const lpContentRes = await getLpContentData({
          $strapiApi,
          slug: params.name,
        })

        if (lpContentRes !== null) {
          const destiSlug = {
            en: params.destination.includes('villas')
              ? 'luxury-villas-rentals'
              : 'luxury-chalets-rentals',
            fr: params.destination.includes('villas')
              ? 'location-villas-luxe'
              : 'location-chalets-luxe',
          }

          return {
            ...response,
            valid: true,
            isLp: true,
            isLpDesti: lpContentRes.isLpDestination,
            isLpInspi: lpContentRes.isLpInspiration,
            searchUrl: {
              en: `${destiSlug.en}/${lpContentRes.slugEn}`,
              fr: `${destiSlug.fr}/${lpContentRes.slugFr}`,
            },
          }
        }

        return { ...response, valid, isDesti: true, searchUrl }
      }
    } catch {
      // if no destination check url SEO facetFilters
      const responseFilterData = await getFacetsFilterWithoutDestination({
        slug: params.name,
        $strapiApi,
      })

      if (responseFilterData) {
        const prefixSearchUrl = getPrefixUrl(responseFilterData.homeType.en)

        return {
          ...response,
          valid: true,
          isSeoFacet: true,
          searchUrl: {
            en: `${prefixSearchUrl.en}/${responseFilterData.urlEn}`,
            fr: `${prefixSearchUrl.fr}/${responseFilterData.urlFr}`,
          },
        }
      }

      // else if no destination and no url SEO facetFilters check the LP
      const lpContentRes = await getLpContentData({
        $strapiApi,
        slug: params.name,
      })

      if (lpContentRes !== null) {
        return {
          ...response,
          valid: true,
          isLp: true,
          isLpDesti: lpContentRes.isLpDestination,
          isLpInspi: lpContentRes.isLpInspiration,
          searchUrl: {
            en: `luxury-villas-rentals/${lpContentRes.slugEn}`,
            fr: `location-villas-luxe/${lpContentRes.slugFr}`,
          },
        }
      }

      return response
    }
  }

  // Else show error page
  return response
}

const getPrefixUrl = (homeType) => {
  const slugFr = `location-${homeType}s-luxe`
  const slugEn = `luxury-${homeType}s-rentals`

  return {
    fr: slugFr,
    en: slugEn,
  }
}
